export class AuthAPI {
  axios;

  constructor(axios) {
    this.axios = axios;
  }

  async getUser() {
    try {
      const response = await this.axios.get("/api/home/user-data");
      return response.data.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
}
