import axios, { Axios, AxiosError } from "axios";
import Cookies from "js-cookie";
import { AuthAPI } from "./auth/auth.api";
// import { AxiosConfig } from '../config/axios';

const CORS_BLOCKED_METHODS = ["delete", "put", "patch"];

class ApiSdk {
  retry = true;
  accessToken = null;
  // private readonly authInstance: Axios;
  authInstance;
  mainInstance;
  auth;

  constructor() {
    // this.authInstance = new AxiosConfig({
    //   baseURL: env.NEXT_PUBLIC_AUTH_API_URL,
    // }).instance;
    // this.authInstance = this.createAxios(env.NEXT_PUBLIC_AUTH_API_URL);
    this.mainInstance = this.createAxios(process.env.REACT_APP_SOCKET_URL);
    this.authInstance = this.createAxios(process.env.REACT_APP_BACKEND_URL);

    this.auth = new AuthAPI(this.authInstance);
  }

  createAxios(baseURL) {
    const ax = axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    ax.interceptors.request.use(async (config) => {
      const token = Cookies.get("access_token");
      if (!token) return config;
      config.headers.Authorization = `Bearer ${token}`;

      //   if (CORS_BLOCKED_METHODS.includes(config.method.toLowerCase())) {
      //     config.headers["Authorization"] = `Bearer ${token}`;
      //     config.withCredentials = false;
      //   }

      return config;
    });

    ax.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.status === 401 || err.status === 403) {
          Cookies.remove("access_token");
          window.location.href = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/login`;
        }
        return Promise.reject(err.message);
      }
    );
    return ax;
  }

  attachToken(token) {
    if (!token) return this;
    if (!!token) {
      this.accessToken = token;

      Object.values(this.getInstances()).forEach((instance) => {
        instance.interceptors.request.use((config) => {
          config.headers.Authorization = `Bearer ${this.accessToken}`;
          return config;
        });
      });
    }
    return this;
  }

  getInstances() {
    return { main: this.mainInstance, auth: this.authInstance };
  }
}

const api = new ApiSdk();
export default api;
