import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Peer from "simple-peer";
import socket from "../utils/socket";
import micOn from "../assets/mic_on.svg";
import micOff from "../assets/mic_off.svg";
import videoOff from "../assets/video_off.svg";
import videoOn from "../assets/video_on.svg";
import callEnd from "../assets/call_end.svg";
import VideoPlayer from "../components/VideoPlayer";
import getIceServers from "../utils/iceServers";
import { StreamContext } from "../context/streamContext";
import { useAuth } from "../hooks/auth/auth.action";

const Room = () => {
  const { roomId } = useParams();
  const {
    user: { type, id: userId },
  } = useAuth();

  const [callAccepted, setCallAccepted] = useState(false);

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [remoteVideo, setRemoteVideo] = useState(true);
  const [remoteAudio, setRemoteAudio] = useState(true);
  const { micState, setMicState, videoState, setVideoState } =
    useContext(StreamContext);

  const userVideo = useRef();
  const partnerVideo = useRef();
  const userStream = useRef();
  const peer = useRef();

  const navigate = useNavigate();

  const callUser = async () => {
    console.log("call user fucntion", userStream.current);
    const iceServers = await getIceServers();
    peer.current = new Peer({
      initiator: true,
      trickle: false,
      stream: userStream.current,
      config: {
        iceServers: iceServers,
      },
    });

    peer.current.on("error", (err) => {
      console.log(err);
    });

    peer.current.on("connect", () => {
      console.log("Connected");
      console.log(peer.current);
    });

    peer.current.on("signal", (data) => {
      console.log("on signal caller side");
      socket.emit("offer", { roomId, from: userId, signal: data });
    });

    peer.current.on("stream", (stream) => {
      console.log("on stream caller side");
      if (partnerVideo.current) {
        partnerVideo.current.srcObject = stream;
      }
    });
  };
  const acceptCall = async (signal) => {
    console.log("accept call function", userStream.current);
    setCallAccepted(true);
    const iceServers = await getIceServers();
    peer.current = new Peer({
      initiator: false,
      trickle: false,
      stream: userStream.current,
      config: {
        iceServers: iceServers,
      },
    });

    // peer.current.on("error", (err) => {
    //   console.log(err);
    // });

    peer.current.on("connect", () => {
      console.log("Connected");
    });

    peer.current.signal(signal);

    peer.current.on("stream", (stream) => {
      console.log("on stream receiver side");
      if (partnerVideo.current) {
        partnerVideo.current.srcObject = stream;
      }
    });

    peer.current.on("signal", (data) => {
      console.log("on signal receiver side");
      socket.emit("answer", { roomId, from: userId, signal: data });
    });

    socket.emit("call-accepted", { roomId, from: userId });
  };

  const endCall = () => {
    console.log("end call");

    if (peer.current) {
      peer.current.destroy();
    }

    if (userStream.current) {
      userStream.current.getTracks().forEach((track) => track.stop());
    }

    setCallAccepted(false);
    if (partnerVideo.current) {
      partnerVideo.current.srcObject = null;
    }

    socket.emit("end-call", { roomId, from: userId });

    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}`;
    // window.location.reload();
  };

  const toggleAudio = () => {
    if (userStream.current) {
      userStream.current
        .getAudioTracks()
        .forEach((track) => (track.enabled = !track.enabled));
    }
    socket.emit("toggle-audio", { roomId, from: userId, isOn: !audio });
    setAudio(!audio);
    setMicState(!micState);
  };
  const toggleVideo = () => {
    if (userStream.current) {
      userStream.current
        .getVideoTracks()
        .forEach((track) => (track.enabled = !track.enabled));
    }
    socket.emit("toggle-video", { roomId, from: userId, isOn: !video });
    setVideo(!video);
    setVideoState(!videoState);
  };

  const remoteAudioToggled = ({ isOn }) => {
    setRemoteAudio(isOn);
  };

  const remoteVideoToggled = ({ isOn }) => {
    setRemoteVideo(isOn);
  };

  const remoteUSerLeft = () => {
    if (peer.current) {
      peer.current.destroy();
    }
    setCallAccepted(false);
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: videoState, audio: micState })
      .then((stream) => {
        userStream.current = stream;
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
      });
  }, []);

  useEffect(() => {
    socket.on("ready-to-call", ({ userId: otherUserId }) => {
      console.log("ready-to-call sodket", otherUserId);
      if (otherUserId !== userId) {
        callUser();
      }
    });

    socket.on("offer", ({ signal }) => {
      console.log("offer socket", signal);
      acceptCall(signal);
    });

    socket.on("answer", ({ signal }) => {
      console.log("answer socket caller side");
      peer.current.signal(signal);
    });

    socket.on("call-accepted", ({ userId: otherUserId }) => {
      console.log("call-accepted", otherUserId);
      setCallAccepted(true);
    });

    socket.on("audio-toggled", remoteAudioToggled);
    socket.on("video-toggled", remoteVideoToggled);

    socket.on("call-ended", () => {
      remoteUSerLeft();
    });

    return () => {
      socket.off("offer");
      socket.off("answer");
      socket.off("ready-to-call");
      socket.off("call-accepted");
      socket.off("call-ended");
      socket.off("audio-toggled");
      socket.off("video-toggled");
    };
  }, [acceptCall, callUser, userId]);

  return (
    <div className=" w-screen h-screen relative p-4 pb-10 flex flex-col gap-2 desktop:p-10 ">
      <div className="flex-1 overflow-hidden relative ">
        <div
          className={`transition-all duration-300 z-10 w-[90px] desktop:w-[304px] h-[172px] desktop:h-[195px] rounded-lg overflow-hidden absolute bottom-4 right-2`}
        >
          <VideoPlayer
            playsInline
            muted
            ref={userVideo}
            className="size-full object-cover object-center scale-x-[-1]"
            autoPlay
          />
        </div>
        <div className="size-full relative ">
          {callAccepted ? (
            <>
              <div className="size-full rounded-xl overflow-hidden">
                <VideoPlayer
                  playsInline
                  ref={partnerVideo}
                  autoPlay
                  className="size-full object-cover object-center"
                />
              </div>
              <div className="absolute top-2 right-2 flex space-x-2">
                {!remoteVideo && (
                  <img src={videoOff} alt="video status" className="w-6 h-6" />
                  // <p className="text-red-500">Audio</p>
                )}
                {!remoteAudio && (
                  <img src={micOff} alt="mic status" className="w-6 h-6" />
                  // <p className="text-red-500 text-3xl">Audio</p>
                )}
              </div>
            </>
          ) : (
            <p className="text-center capitalize text-xl font-bold desktop:text-3xl">
              {type === "doctor"
                ? "waiting for the patient to join!"
                : "waiting for the doctor to join!"}
            </p>
          )}
        </div>
      </div>
      <div className="py-8 flex justify-center items-center gap-5">
        <button className="size-[63px]" onClick={toggleVideo}>
          <img src={video ? videoOn : videoOff} alt="video" />
        </button>
        <button className="size-[63px]" onClick={endCall}>
          <img src={callEnd} alt="call end" />
        </button>
        <button className="size-[63px]" onClick={toggleAudio}>
          <img src={audio ? micOn : micOff} alt="mic" />
        </button>
      </div>
    </div>
  );
};

export default Room;
