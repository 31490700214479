import { QueryClient } from "@tanstack/react-query";
import useLocalStorage from "react-use-localstorage";
import { AuthService } from "../../lib/services/auth.service";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", null);
  const token = Cookies.get("access_token");
  if (token && user === null) {
    console.log("here hook");
    AuthService.getCurrentUser()
      .then((user) => setUser(JSON.stringify(user)))
      .catch(() => {
        localStorage.removeItem("user");
        navigate("http://localhost:8000/login");
      });
  }

  return { user: JSON.parse(user), setUser };
};

export const getUser = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery({
    queryKey: ["current_user"],
    queryFn: AuthService.getCurrentUser,
  });
};
