import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import api from "../../lib/api";
import { useAuth } from "../../hooks/auth/auth.action";

const ProtectedRoute = ({ children }) => {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (!token) {
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login`;
    }
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const token = Cookies.get("access_token");
  //     if (token) {
  //       if (user) {
  //         setIsLoading(false); // Logic finished, allow rendering
  //         return;
  //       } else {
  //         const fetchedUser = await api.attachToken(token).auth.getUser();
  //         setUser(fetchedUser);
  //         setIsLoading(false);
  //         return;
  //       }
  //     } else if (!token) {
  //       localStorage.removeItem("user");
  //       Cookies.remove("access_token");
  //       setIsLoading(false);
  //       window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login`;
  //       return;
  //     } else {
  //       setIsLoading(false);
  //       return;
  //     }
  //   };

  //   fetchUser();
  // }, [user, setUser]);

  if (isLoading) {
    return <div>Loading...</div>; // Placeholder while loading
  }

  return children; // Render the target page after logic is complete
};

export default ProtectedRoute;
