import api from "../api";

export class AuthService {
  static async getCurrentUser() {
    try {
      const response = await api.auth.getUser();
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
}
