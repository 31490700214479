import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import socket from "../utils/socket";

const Lobby = () => {
  const [email, setEmail] = useState("");
  const [roomId, setRoomId] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = email; // Use email as userId for simplicity
    socket.emit("join-room", { roomId, userId });
    navigate(`/room/${roomId}?userId=${userId}`);
  };

  return <p>HFP-VIDEO-CONSULTATION</p>;
};

export default Lobby;
