import socket from "../utils/socket";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/auth/auth.action";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";
import { useContext, useEffect, useRef, useState } from "react";
import micOn from "../assets/mic_on.svg";
import micOff from "../assets/mic_off.svg";
import videoOff from "../assets/video_off.svg";
import videoOn from "../assets/video_on.svg";
import { StreamContext } from "../context/streamContext";

const Entry = () => {
  const navigate = useNavigate();
  const { roomId } = useParams("roomId");
  const {
    user: { id: userId },
  } = useAuth();
  // const userId = JSON.parse(user).id ?? "";
  const userVideo = useRef();
  const userStream = useRef();
  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const { micState, setMicState, videoState, setVideoState } =
    useContext(StreamContext);

  // const { linkData } = useValidateLink();

  const handleSubmit = () => {
    socket.emit("join-room", { roomId, userId });
    navigate(`/room/${roomId}?userId=${userId}`);
  };

  const toggleAudio = () => {
    if (userStream.current) {
      userStream.current
        .getAudioTracks()
        .forEach((track) => (track.enabled = !track.enabled));
    }
    socket.emit("toggle-audio", { roomId, from: userId, isOn: !audio });
    setAudio(!audio);
    setMicState(!micState);
  };
  const toggleVideo = () => {
    if (userStream.current) {
      userStream.current
        .getVideoTracks()
        .forEach((track) => (track.enabled = !track.enabled));
    }
    socket.emit("toggle-video", { roomId, from: userId, isOn: !video });
    setVideo(!video);
    setVideoState(!videoState);
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: videoState, audio: micState })
      .then((stream) => {
        userStream.current = stream;
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
      });
  }, []);

  return (
    <div className="size-full p-4 flex flex-col gap-5">
      <div className="flex-1 rounded-lg bg-[#DBDCFF] desktop:bg-white flex flex-col desktop:flex-row-reverse gap-8 items-center justify-end desktop:justify-center p-4 ">
        <div className="flex flex-col gap-4">
          <p className="text-base font-medium text-center desktop:text-3xl">
            Your appointment will start at <br /> 10:00 AM
          </p>
          <button
            className="bg-primary-600  text-white py-1.5 rounded-md hidden desktop:block"
            onClick={handleSubmit}
          >
            Join the call
          </button>
        </div>
        <div className="self-end flex flex-col gap-1 desktop:gap-4 desktop:items-center desktop:self-center">
          <div className="w-[90px] h-[172px] desktop:w-[784px] desktop:h-[404px] rounded-md overflow-hidden">
            <VideoPlayer
              className="size-full object-cover object-center scale-x-[-1]"
              playsInline
              muted
              ref={userVideo}
              autoPlay
            />
          </div>
          <div className="flex gap-2 desktop:gap-6">
            <button className="w-10 desktop:w-16" onClick={toggleVideo}>
              <img src={video ? videoOn : videoOff} alt="video" />
            </button>
            <button className="w-10 desktop:w-16" onClick={toggleAudio}>
              <img src={audio ? micOn : micOff} alt="mic" />
            </button>
          </div>
        </div>
      </div>
      <button
        className="bg-primary-600  text-white py-1.5 rounded-md desktop:hidden"
        onClick={handleSubmit}
      >
        Join the call
      </button>
    </div>
  );
};

export default Entry;
