import { createContext, useState } from "react";

export const StreamContext = createContext(null);

export default function StreamProvider({ children }) {
  const [micState, setMicState] = useState(true);
  const [videoState, setVideoState] = useState(true);

  return (
    <StreamContext.Provider
      value={{ micState, setMicState, videoState, setVideoState }}
    >
      {children}
    </StreamContext.Provider>
  );
}
