import { Route, Routes } from "react-router-dom";
import Lobby from "./pages/Lobby";
import Room from "./pages/Room";
import Entry from "./pages/Entry";
import ProtectedRoute from "./components/protectedRoute";

function App() {
  return (
    <div className="w-screen h-screen">
      <Routes>
        <Route path="/" element={<Lobby />} />
        <Route
          path="/room/:roomId"
          element={
            <ProtectedRoute>
              <Room />
            </ProtectedRoute>
          }
        />
        <Route
          path="/lobby/:roomId"
          element={
            <ProtectedRoute>
              <Entry />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
