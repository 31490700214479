import React, { forwardRef } from "react";

const VideoPlayer = forwardRef((props, ref) => {
  return (
    <video
      ref={ref}
      playsInline
      className={props.className}
      autoPlay
      muted={props.muted}
    />
  );
});

export default VideoPlayer;
